import React from 'react'

const VideoBanner = () => {
  return (
    <div className='Event_Booking-Video'>
        <img src='./Image/15.png' alt='' />
    </div>
  )
}

export default VideoBanner